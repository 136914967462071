<div>
  <mat-accordion [@.disabled]="disableAnimation" displayMode="flat">
    <a class="sidebar-logo d-block mb-3" routerLink="/">
      <img src="../../../assets/images/logoImage.png" alt="" class="logo" />
    </a>
    <mat-expansion-panel
      #panel1
      routerLinkActive="active-item"
      #rla1="routerLinkActive"
      expanded="true"
    >
      <mat-expansion-panel-header expanded="true" class="onhover">
        <mat-panel-title>
          <span class="material-symbols-outlined">lab_profile</span> Reports
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <mat-accordion class="example-headers-align" multi="false">
          <mat-expansion-panel showToggle="true">
            <mat-expansion-panel-header class="onhover">
              <mat-panel-title>
                <span class="material-symbols-outlined pe-0 iconColor">
                  gite
                </span>
                <span
                  class="d-inline-block me-1 textColor"
                  title="License Compliance"
                >
                  LC & Rural</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <mat-expansion-panel showToggle="true" class="onhover">
                <mat-expansion-panel-header>
                  <mat-panel-title class="innerText"> CAF </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/caf-penalty']"
                      >Penalty</a
                    >
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/caf-audit']"
                      >Audit</a
                    >
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/caf-year-wise/realtime']"
                      >Year Wise</a
                    >
                  </mat-nav-list>
                </ng-template>
              </mat-expansion-panel>
              <mat-expansion-panel showToggle="true" class="onhover">
                <mat-expansion-panel-header>
                  <mat-panel-title class="innerText"> EMF </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-audit/']"
                      >Audit</a
                    >
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-penalty-radiation']"
                      >Penalty Radiation</a
                    >
                  </mat-nav-list>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-penalty-self-certification']"
                      >Penalty Self Certification</a
                    >
                  </mat-nav-list>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-penalty-signage']"
                      >Penalty Signage</a
                    >
                  </mat-nav-list>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-penalty-total/mis-tsp']"
                      >Penalty Total</a
                    >
                  </mat-nav-list>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-testing']"
                      >Testing</a
                    >
                  </mat-nav-list>
                  <mat-nav-list>
                    <a
                      mat-list-item
                      routerLinkActive="active-link"
                      [routerLink]="['/reports/emf-year-wise/mis-realtime']"
                      >Year Wise</a
                    >
                  </mat-nav-list>
                </ng-template>
              </mat-expansion-panel>
              <mat-nav-list class="mt-2">
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/village']"
                  >Village</a
                >
              </mat-nav-list>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel showToggle="true" class="onhover">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="material-symbols-outlined pe-0 iconColor">
                  lock_reset
                </span>
                <span
                  class="d-inline-block me-1 textColor"
                  title="License Compliance"
                >
                  Security</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/security-illegal-setup/all']"
                  >Illegal Setup</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/grey-market-sop']"
                  >Grey Market SOP</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/security-kpi-grey-market']"
                  >KPI Grey Market</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/security-kpi-summary-grey-market']"
                  >KPI Summary Grey Market</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/rmc-interceptions-average-delay']"
                  ><span class="d-block">RMC Interceptions & </span>
                  <span class="d-block">Average Delay In validation</span></a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ims-pci']"
                  >IMS PCI</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ims-pci-summary']"
                  >IMS PCI Summary</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ims-fault']"
                  >IMS Fault</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ims-faults-summary']"
                  >IMS Faults Summary</a
                >
              </mat-nav-list>
            </ng-template>
          </mat-expansion-panel>
          <mat-expansion-panel showToggle="true" class="onhover">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="material-symbols-outlined pe-0 iconColor"
                  >supervisor_account</span
                >
                <span
                  class="d-inline-block me-1 textColor"
                  title="License Compliance"
                >
                  A & HR</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ahr-sde-jto/']"
                  >SDE JTO Status</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ahr-staff-status/']"
                  >Staff Status</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ahr-court-cases/']"
                  >Court Cases</a
                >
              </mat-nav-list>
            </ng-template>
          </mat-expansion-panel>

          <mat-expansion-panel showToggle="true" class="onhover">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="material-symbols-outlined pe-0 iconColor"
                  >leaderboard</span
                >
                <span
                  class="d-inline-block me-1 textColor"
                  title="License Compliance"
                >
                  NBM</span
                >
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/lsa-ranking']"
                  class="innerText"
                  >LSA Ranking</a
                >
              </mat-nav-list>

              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/bts-fiberization']"
                  >BTS Fiberization</a
                >
              </mat-nav-list>
              <mat-nav-list>
                <a
                  mat-list-item
                  routerLinkActive="active-link"
                  [routerLink]="['/reports/ofc-laid']"
                  >OFC laid</a
                >
              </mat-nav-list>
            </ng-template>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-nav-list>
          <a
            mat-list-item
            routerLinkActive="active-link"
            [routerLink]="['/reports/dynamic-report']"
            class="innerText"
            style="padding: 0px 10px 0px 15px !important"
          >
            <span class="material-symbols-outlined pe-0 iconColor"
              >leaderboard</span
            >
            Dynamic report</a
          >
        </mat-nav-list>
      </ng-template>
    </mat-expansion-panel>

    <mat-expansion-panel
      *ngIf="user?.isAdmin && user.level === 'dgt'"
      #panel2
      routerLinkActive="active-item"
      #rla2="routerLinkActive"
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
    >
      <mat-expansion-panel-header class="onhover">
        <mat-panel-title>
          <span class="material-symbols-outlined"> admin_panel_settings </span
          >Admin
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-nav-list *ngIf="user.level === 'dgt'">
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/report']"
          ><span class="material-symbols-outlined"> draft </span>Report</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/subreport']"
          ><span class="material-symbols-outlined"> file_copy </span
          >Sub-Report</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/users']"
          ><span class="material-symbols-outlined"> group </span>User</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/vertical']"
          ><span class="material-symbols-outlined"> diversity_3 </span
          >Vertical</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/tsp']"
          ><span class="material-symbols-outlined"> cell_tower </span>TSP</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/state']"
          ><span class="material-symbols-outlined"> holiday_village </span
          >State</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/district']"
          ><span class="material-symbols-outlined"> domain </span>District</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/sub-district']"
          ><span class="material-symbols-outlined"> apartment </span>Sub
          District</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/block']"
          ><span class="material-symbols-outlined"> corporate_fare </span
          >Block</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/gram-panchayat']"
          ><span class="material-symbols-outlined"> real_estate_agent </span
          >Gram Panchayat</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/village']"
          ><span class="material-symbols-outlined"> villa </span>Village</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/lsa']"
          ><span class="material-symbols-outlined"> house_siding </span>LSA</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/tower-or-site']"
          ><span class="material-symbols-outlined"> podcasts </span>Tower Or
          Site</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/tower-or-site-type']"
          ><span class="material-symbols-outlined"> wifi_tethering</span>Tower
          Or Site Type</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/petitioner']"
          ><span class="material-symbols-outlined"> contact_page</span
          >Petitioner</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/case-matter-keyword']"
          ><span class="material-symbols-outlined"> manage_search</span>Case
          Matter Keyword</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/field-unit']"
          ><span class="material-symbols-outlined"> partner_exchange</span>Field
          Unit</a
        >
        <a
          mat-list-item
          routerLinkActive="active-link"
          [routerLink]="['/admin/grade-meta-data']"
          ><span class="material-symbols-outlined">Grade</span>Grade</a
        >
      </mat-nav-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>
