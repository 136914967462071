import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import {Router} from '@angular/router';

import {catchError, Observable, switchMap, throwError} from 'rxjs';

import {CoreService} from 'src/app/services/core/core.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private authService: CoreService, private router: Router) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        'X-Frame-Options':
          'deny, sameorigin, allow-from <https://dev.app.dgt.netoyed.com.au/>',
      },
    });

    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401 && sessionStorage.getItem('accessToken')) {
          return this.authService.onRefreshToken().pipe(
            switchMap(() => {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${sessionStorage.getItem(
                    'accessToken',
                  )}`,
                },
              });
              return next.handle(request);
            }),
            catchError(refreshTokenError => {
              return throwError(() => {
                if (refreshTokenError) {
                  sessionStorage.clear();
                  this.router.navigateByUrl('/login');
                }
              });
            }),
          );
        } else {
          return throwError(() => e);
        }
      }),
    );
  }
}

export const AUTHORIZATION_INTERCEPTOR = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthorizationInterceptor,
  multi: true,
};
