import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthorizedGuard} from './guards/authorized/authorized.guard';
import {IsAdminGuard} from './guards/is-admin/is-admin.guard';
import {UnauthorizedGuard} from './guards/unauthorized/unauthorized.guard';
import {AuthLayoutComponent} from './layouts/auth-layout/auth-layout.component';
import {StandardLayoutComponent} from './layouts/standard-layout/standard-layout.component';

const routes: Routes = [
  {
    path: '',
    component: StandardLayoutComponent,
    canActivate: [AuthorizedGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            d => d.DashboardModule,
          ),
      },
      {
        path: 'change-password',
        loadChildren: () =>
          import('./pages/change-password/change-password.module').then(
            c => c.ChangePasswordModule,
          ),
      },
      {
        path: 'reports',
        children: [
          {
            path: 'village',
            loadChildren: () =>
              import('./pages/reports/village/village.module').then(
                r => r.VillageModule,
              ),
          },
          {
            path: 'vvp',
            loadChildren: () =>
              import('./pages/reports/security-vvp/vvp.module').then(
                v => v.VvpModule,
              ),
          },
          {
            path: 'caf-penalty',
            loadChildren: () =>
              import('./pages/reports/caf-penalty/caf-penalty.module').then(
                c => c.CafPenaltyModule,
              ),
          },
          {
            path: 'caf-audit',
            loadChildren: () =>
              import('./pages/reports/caf-audit/caf-audit.module').then(
                a => a.CafAuditModule,
              ),
          },
          {
            path: 'caf-year-wise',
            loadChildren: () =>
              import('./pages/reports/caf-year-wise/caf-year-wise.module').then(
                c => c.CafYearWiseModule,
              ),
          },
          {
            path: 'emf-audit',
            loadChildren: () =>
              import('./pages/reports/emf-audit/emf-audit.module').then(
                a => a.EmfAuditModule,
              ),
          },
          {
            path: 'emf-penalty-radiation',
            loadChildren: () =>
              import(
                './pages/reports/emf-penalty-radiation/emf-penalty-radiation.module'
              ).then(a => a.EmfPenaltyRadiationModule),
          },
          {
            path: 'emf-penalty-self-certification',
            loadChildren: () =>
              import(
                './pages/reports/emf-penalty-self-certification/emf-penalty-self-certification.module'
              ).then(a => a.EmfPenaltySelfCertificationModule),
          },
          {
            path: 'emf-penalty-signage',
            loadChildren: () =>
              import(
                './pages/reports/emf-penalty-signage/emf-penalty-signage.module'
              ).then(a => a.EmfPenaltySignageModule),
          },
          {
            path: 'emf-penalty-total',
            loadChildren: () =>
              import(
                './pages/reports/emf-penalty-total/emf-penalty-total.module'
              ).then(a => a.EmfPenaltyTotalModule),
          },
          {
            path: 'emf-testing',
            loadChildren: () =>
              import('./pages/reports/emf-testing/emf-testing.module').then(
                a => a.EmfTestingModule,
              ),
          },
          {
            path: 'emf-year-wise',
            loadChildren: () =>
              import('./pages/reports/emf-year-wise/emf-year-wise.module').then(
                a => a.EmfYearWiseModule,
              ),
          },
          {
            path: 'security-illegal-setup-sim/:sisId',
            loadChildren: () =>
              import(
                './pages/reports/security-illegal-setup-sim/security-illegal-setup-sim.module'
              ).then(a => a.SecurityIllegalSetupSimModule),
          },
          {
            path: 'security-illegal-setup-pos/:sisId',
            loadChildren: () =>
              import(
                './pages/reports/security-illegal-setup-pos/security-illegal-setup-pos.module'
              ).then(a => a.SecurityIllegalSetupPosModule),
          },
          {
            path: 'security-illegal-setup-sim-box/:sisId',
            loadChildren: () =>
              import(
                './pages/reports/security-illegal-setup-sim-box/security-illegal-setup-sim-box.module'
              ).then(a => a.SecurityIllegalSetupSimBoxModule),
          },
          {
            path: 'security-illegal-setup',
            loadChildren: () =>
              import(
                './pages/reports/security-illegal-setup/security-illegal-setup.module'
              ).then(a => a.SecurityIllegalSetupModule),
          },
          {
            path: 'security-kpi-grey-market',
            loadChildren: () =>
              import(
                './pages/reports/security-kpi-grey-market/security-kpi-grey-market.module'
              ).then(g => g.SecurityKpiGreyMarketModule),
          },
          {
            path: 'security-kpi-summary-grey-market',
            loadChildren: () =>
              import(
                './pages/reports/security-kpi-summary-grey-market/security-kpi-summary-grey-market.module'
              ).then(k => k.SecurityKpiSummaryGreyMarketModule),
          },
          {
            path: 'rmc-interceptions-average-delay',
            loadChildren: () =>
              import(
                './pages/reports/security-rmc-interceptions-average-delay/rmc-interceptions-average-delay.module'
              ).then(r => r.RmcInterceptionsAverageDelayModule),
          },
          {
            path: 'ims-pci',
            loadChildren: () =>
              import(
                './pages/reports/security-ims-pci/security-ims-pci.module'
              ).then(i => i.SecurityImsPciModule),
          },
          {
            path: 'ims-pci-summary',
            loadChildren: () =>
              import(
                './pages/reports/security-ims-pci-summary/security-ims-pci-summary.module'
              ).then(i => i.SecurityImsPciSummaryModule),
          },
          {
            path: 'ims-fault',
            loadChildren: () =>
              import(
                './pages/reports/security-ims-fault/security-ims-fault.module'
              ).then(f => f.SecurityImsFaultModule),
          },
          {
            path: 'ims-faults-summary',
            loadChildren: () =>
              import(
                './pages/reports/ims-faults-summary/ims-faults-summary.module'
              ).then(i => i.ImsFaultsSummaryModule),
          },
          {
            path: 'ahr-sde-jto',
            loadChildren: () =>
              import('./pages/reports/ahr-sde-jto/ahr-sde-jto.module').then(
                a => a.AhrSdeJtoModule,
              ),
          },
          {
            path: 'ahr-staff-status',
            loadChildren: () =>
              import(
                './pages/reports/ahr-staff-status/ahr-staff-status.module'
              ).then(a => a.AhrStaffStatusModule),
          },
          {
            path: 'ahr-court-cases',
            loadChildren: () =>
              import(
                './pages/reports/ahr-court-cases/ahr-court-cases.module'
              ).then(a => a.AhrCourtCasesModule),
          },
          {
            path: 'lsa-ranking',
            loadChildren: () =>
              import('./pages/reports/lsa-ranking/lsa-ranking.module').then(
                a => a.LsaRankingModule,
              ),
          },
          {
            path: 'grey-market-sop',
            loadChildren: () =>
              import(
                './pages/reports/grey-market-sop/grey-market-sop.module'
              ).then(g => g.GreyMarketSopModule),
          },
          {
            path: 'bts-fiberization',
            loadChildren: () =>
              import(
                './pages/reports/bts-fiberization/bts-fiberization.module'
              ).then(b => b.BtsFiberizationModule),
          },
          {
            path: 'ofc-laid',
            loadChildren: () =>
              import('./pages/reports/ofc-laid/ofc-laid.module').then(
                a => a.OfcLaidModule,
              ),
          },

          {
            path: 'dynamic-report',
            loadChildren: () =>
              import(
                './pages/reports/dynamic-report/dynamic-report.module'
              ).then(a => a.DynamicReportModule),
          },
        ],
      },
      {
        path: 'admin',
        canLoad: [IsAdminGuard],
        canActivate: [IsAdminGuard],
        loadChildren: () =>
          import('./pages/admin/admin.module').then(m => m.AdminModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [UnauthorizedGuard],
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('./pages/login/login.module').then(l => l.LoginModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./pages/reset-password/reset-password.module').then(
            r => r.ResetPasswordModule,
          ),
      },
      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./pages/forgot-password/forgot-password.module').then(
            f => f.ForgotPasswordModule,
          ),
      },
    ],
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./pages/not-found/not-found.module').then(n => n.NotFoundModule),
  },
  {
    path: 'error-found',
    loadChildren: () =>
      import('./pages/error-found/error-found.module').then(
        e => e.ErrorFoundModule,
      ),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
