import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, map, tap} from 'rxjs';

import * as moment from 'moment';

import {User} from 'src/app/interfaces/user.interface';
import {environment} from 'src/environments/environment';
import {LoaderService} from '../loader/loader.service';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export interface IRefreshToken {
  accessToken: string;
  expireAt: Date;
}

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  private readonly userStr = sessionStorage.getItem('user');
  readonly user$ = new BehaviorSubject<User | null>(
    this.userStr ? JSON.parse(this.userStr) : null,
  );
  constructor(private http: HttpClient, private loaderService: LoaderService) {}

  onRefreshToken(): Observable<IRefreshToken> {
    this.loaderService.show();
    return this.http
      .put<IRefreshToken>(environment.api + 'accounts/refresh-token', {
        refreshToken: sessionStorage.getItem('refreshToken'),
      })
      .pipe(
        tap((data: IRefreshToken) => {
          sessionStorage.setItem('accessToken', data.accessToken);
          this.loaderService.hide();
        }),
      );
  }
  whoAmI() {
    const headers = new HttpHeaders().set(
      'Authorization',
      `Bearer ${sessionStorage.getItem('accessToken')}`,
    );
    return this.http
      .get<User>(environment.api + 'accounts/whoami', {headers})
      .pipe(
        map(user => {
          this.user$.next(user);
          sessionStorage.setItem('user', JSON.stringify(user));
          return user;
        }),
      );
  }
}
export function dateFormat(date: Date | string = new Date()): Date {
  const ctrlValue = moment(date);
  const month = ctrlValue.month();
  const year = ctrlValue.year();
  return new Date(
    `${year}-${month >= 9 ? month + 1 : `0${month + 1}`}-01T00:00:00.000Z`,
  );
}
export function isValidString(str: unknown): boolean {
  return str !== '' && typeof str === 'string' && str.length < 1500;
}
export function isBooleanNumber(val: unknown): boolean {
  return (
    (val as string).toLowerCase() === 'yes' ||
    (val as string).toLowerCase() === 'no'
  );
}
export function isValidNumber(num: unknown) {
  return num !== '' && num != null && typeof num === 'number' && num >= 0;
}

export function isValidInteger(num: unknown) {
  return num !== '' && num != null && typeof num === 'number';
}

export const PASSWORD_PATTERN =
  /^.*(?=.{8,})(?=.*[a-zA-Z])(?=.*\d)(?=.*[&%?$*@#!]).*$/;
export const MAX_UNSIGNED_INT = 4294967295;
export const MAX_UNSIGNED_BIG_INT = Math.pow(2, 64) - 1;
export const PAGE_SIZE_OPTIONS = [25, 75, 150, 250, 500, 1000];
export function phoneNumberValidator(
  control: FormControl,
): {[key: string]: boolean} | null {
  const phoneNumberPattern = /^\d{10}$/;
  if (control.value && !phoneNumberPattern.test(control.value)) {
    return {invalidPhoneNumber: true};
  }
  return null;
}
export function fifteenDigitIntegerValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const value = control.value || null;
    const isValid = /^\d{15}$/.test(value) || null;
    return isValid ? null : {fifteenDigits: true};
  };
}
export function validateMonetaryValue(
  control: FormControl,
): ValidationErrors | null {
  const value = control.value;
  const isValid = /^\d+(\.\d{1,2})?$/.test(value);

  return isValid ? null : {invalidMonetaryValue: true};
}

export function isoDateConverter(value: Date) {
  const date = new Date(value);
  const istOffset = 5.5 * 60; // IST offset in minutes (5 hours and 30 minutes)
  const istTimestamp = date.getTime() + istOffset * 60 * 1000;
  const istDate = new Date(istTimestamp);
  const istISOString = istDate.toISOString();
  return istISOString;
}

export function convertReportDate(reportDate: string) {
  const date = new Date(reportDate);
  const month = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(date);
  const year = date.getFullYear();
  return `${month.valueOf()} ${year}`;
}
